<template>
  <div>
    <div class="m-4 p-4">
      <!-- active-nav-item-class="font-weight-bold text-uppercase text-blue" -->
      <b-tabs pills card vertical start nav-wrapper-class="w-30">
        <b-tab active>
          <template #title>
            <!-- <img width="100px" src="@/assets/mum_boy2.svg" alt="" /> -->
            <p>{{ $t("InfoGraphicTheme_1") }}</p>
          </template>
          <div class="mt-3">
            <b-row>
              <b-col class="text-center">
                <img
                  @click="OpemImageQabul"
                  style="cursor:pointer"
                  src="@/assets/qabul_infoGraphic.svg"
                  alt=""
                />
              </b-col>
            </b-row>
            <b-modal
              hide-footer
              class="text-center"
              size="xl"
              v-model="ModalImgQabul"
            >
              <b-row>
                <b-col class="text-center">
                  <img
                    width="100%"
                    src="@/assets/qabul_infoGraphic.svg"
                    alt=""
                  />
                </b-col>
              </b-row>
            </b-modal>
          </div>

          <!-- <div class="m-3 p-3">
            <b-row>
              <b-col>
                <div class="textColorViewStudentQabul text-center">
                  <p>
                    {{ $t("InfoGraphic_1") }}
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row class="m-3">
              <b-col></b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center cols-sm-12">
                      <img width="80%" src="@/assets/director.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange1 = !ischange1"
                          variant="white"
                          class="text"
                        >
                          
                          <b-icon
                            class="IconStyle"
                            :icon="ischange1 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon>
                        </b-button>
                        <b-icon icon="arrow-right-circle-fill"></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange1"
                        id="accordion-1"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Мактаб раҳбарияти мазкур муассасага бириктирилган
                              ҳудуддаги мактаб ёшидаги болаларни ўз вақтида
                              умумтаълим муассасасига ўқишга жалб қилиш учун
                              масъул ҳисобланади. Умумий ўрта таълим
                              ташкилотининг биринчи синфига болалар улар етти
                              ёшга тўладиган йилда қабул қилинади. <br />
                              Мактаб ёшидаги болаларни таълимга қамраб олиш
                              туман (шаҳар) ҳокимларининг қарори билан
                              мактабларга бириктирилган ҳудудлар (микроҳудуд)
                              доирасида мактаб педагог ходимлари томонидан
                              болаларни бирма-бир (уйма-уй) рўйхатга олиш орқали
                              амалга оширилади.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                      <div></div></b-col
                  ></b-row> </b-card
              ></b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/mum_boy2.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange2 = !ischange2"
                          variant="white"
                          class="text"
                        >
                          
                          <b-icon
                            class="IconStyle"
                            :icon="ischange2 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon
                        ></b-button>
                        <b-icon icon="arrow-right-circle-fill"></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange2"
                        id="accordion-2"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Болаларни мактабларнинг 1 синфига қабул қилиш учун
                              уларнинг ота-оналари томонидан мактабга
                              топшириладиган ҳужжатлар.
                              <br />
                              - Болалар умумий мактабларнинг 1 синфига
                              тайёргарлик даражасидан қатъи назар, танловсиз
                              қабул қилинади.
                              <br />
                              - Ота-онанинг бошқа ҳудудда яшаши (прописканинг
                              йўқлиги) болани мактабга қабул қилишни рад этиш
                              учун асос бўлмайди.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card></b-col
              >
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/girl_document.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange3 = !ischange3"
                          variant="white"
                          class="text"
                        >
                          
                          <b-icon
                            class="IconStyle"
                            :icon="ischange3 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon
                        ></b-button>
                        <b-icon icon="arrow-right-circle-fill"></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange3"
                        id="accordion-3"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              - ота-оналар томонидан мактаб директори номига
                              ариза;
                              <br />
                              - ота-оналарнинг фуқаролик паспорти (фуқаролиги
                              бўлмаган шахсларнинг Ўзбекистонда яшаш
                              гувоҳномаси) нусхаси (доимий яшаш жойи ёки
                              вақтинча турган жойи бўйича рўйхатга олинганлиги
                              ҳақидаги қайд ёзуви кўрсатилган ҳолда);
                              <br />
                              - боланинг туғилганлик ҳақидаги гувоҳномаси
                              нусхаси;
                              <br />
                              - боланинг соғлиғи тўғрисида тиббий маълумотнома;
                              <br />
                              боланинг 3,5 х 4,5 см ҳажмдаги 4 дона рангли
                              фотосурати.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card></b-col
              >
              <b-col></b-col>
            </b-row>
            <b-row class="m-3">
              <b-col></b-col>
              <b-col class="mt-4 ">
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="120%" src="@/assets/adm.png" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange4 = !ischange4"
                          variant="white"
                          class="text"
                        >
                         
                          <b-icon
                            class="IconStyle"
                            :icon="ischange4 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon
                        ></b-button>
                        <b-icon icon="arrow-right-circle-fill"></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange4"
                        id="accordion-4"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Ўқув йили умумтаълим муассасаларида 2 сентябрдан
                              келгуси йилнинг 25 майига қадар давом этади ва
                              тўртта чоракка бўлинади. <br />
                              1-босқичда ота-оналар 2021 йилнинг 15 июнидан 5
                              июлига қадар
                              <a
                                target="_blank"
                                style="color:red"
                                href="https://my.maktab.uz/"
                                >my.maktab.uz</a
                              >
                              электрон плафтормаси орқали рўйхатдан ўтиб,
                              фарзандлари 1-синфга чиқадиган мактабларни
                              танлайдилар. Бунда 3 та мактабни танлаш имконияти
                              берилади. - Биринчи мактаб
                              <br />
                              - ота-она доимий истиқомат қилаётган
                              микроҳудуддаги мактаб;
                              <br />
                              - Иккинчи ва учинчи мактаблар - ота-оналарнинг
                              ихтиёрий танлови асосида фарзандларини қатнаши
                              учун қулай бўлган мактаблар.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col></b-row
                  >
                </b-card></b-col
              >

              <b-col>
                <b-card class="InfografiyaViewEnterSchool">
                  <b-row>
                    <b-col>
                      <div class="text-center">
                        <p class="textColorViewStudent">
                          {{ $t("InfoGraphicTheme_1") }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/entter_school1.svg" />
                    </b-col>
                  </b-row>
                 
                </b-card>
              </b-col>
              <b-col class="mt-4 ">
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/boy.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange6 = !ischange6"
                          variant="white"
                          class="text"
                        >
                        
                          <b-icon
                            class="IconStyle"
                            :icon="ischange6 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon icon="arrow-right-circle-fill"></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange6"
                        id="accordion-6"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              2-босқичда 2021 йилнинг 6 июлидан 31 июлга қадар
                              ота-оналар электрон плафторма орқали танлаган
                              биринчи мактабга, яъни бириктирилган
                              микроҳудуддаги мактабга ҳужжатларини топширадилар,
                              бунда ҳужжатларни топшириш тўлиқ электрон шаклда
                              амалга оширилади. <br />
                              Aгар ота-оналар микроҳуддуддаги мактабни эмас,
                              бошқа мактабларни танлаган бўлса, яъни электрон
                              рўйхатдан ўтиш вақтида танлаган иккинчи ёки учинчи
                              мактабларнинг бирида бўш ўринлар бўлган тақдирда
                              1-15 август кунлари ҳужжатларини топширишлари
                              мумкин бўлади.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col></b-col>
            </b-row>

            <b-row class="m-3">
              <b-col></b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/offline_document.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange7 = !ischange7"
                          variant="white"
                          class="text"
                        >
                          
                          <b-icon
                            class="IconStyle"
                            :icon="ischange7 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon icon="arrow-right-circle-fill"></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange7"
                        id="accordion-7"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Қолган ҳудудларда 1-синфга қабул анъанавий шаклда,
                              яъни бевосита мактабда амалга оширилади. Бунда
                              ота-оналар 15 июндан -15 июлга қадар доимий яшаш
                              манзили бўйича бириктирилган микроҳудуддаги
                              мактабга фарзандларини 1-синфга жойлаштриш учун
                              ҳужжатларини бевосита топширишлари мумкин.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/students.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange8 = !ischange8"
                          variant="white"
                          class="text"
                        >
                         
                          <b-icon
                            class="IconStyle"
                            :icon="ischange8 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon icon="arrow-right-circle-fill"></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange8"
                        id="accordion-8"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              - Мактабда синфлардаги болалар сони 35 нафардан
                              ортиб кетишига йўл қўйилмайди. <br />
                              - Кам комплектли умумтаълим муассасаларида
                              (филиалларда) бошланғич синф ўқувчилари қуйидаги
                              ҳолларда битта синфга бирлаштирилиши мумкин:
                              <br />
                              - ўқувчилар сони 8 нафаргача бўлган I — IV
                              синфлар;
                              <br />
                              - ўқувчилар сони 12 нафаргача бўлган учта
                              бошланғич синф;
                              <br />
                              - ўқувчилар сони 24 нафаргача бўлган иккита
                              бошланғич синф (I ва III, II ва IV синфлар).
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col></b-row
                  >
                </b-card></b-col
              >
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/study_info.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange9 = !ischange9"
                          variant="white"
                          class="text"
                        >
                          
                          <b-icon
                            class="IconStyle"
                            :icon="ischange9 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon icon="arrow-right-circle-fill"></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange9"
                        id="accordion-9"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              ўқув йилининг давомийлиги — 34 ҳафта (I синфлар
                              учун 33 ҳафта); <br />
                              - ўқув ҳафтасининг давомийлиги — 6 кун (педагогик
                              кенгашнинг қарорига кўра I синфлар учун 5 кун);
                              <br />
                              - дарс машғулотининг давомийлиги — 45 дақиқа;
                              <br />
                              - дарс машғулотлари оралиғидаги танаффуснинг
                              давомийлиги — 5 — 10 дақиқа (ўқув куни ўртасидаги
                              танаффуснинг давомийлиги — 15 — 30 дақиқа). <br />
                              - Меҳнат тўғрисидаги қонун ҳужжатларида байрам
                              (ишланмайдиган) куни деб белгиланган кунларда дарс
                              машғулотлари ўтказилмайди. <br />
                              - Байрам (ишланмайдиган) кунларига тўғри келиб
                              қолган дарс машғулотларининг мавзулари ўқув
                              дастурларини ихчамлаштирган ҳолда кейинги дарс
                              машғулотларида ўтказилади.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card></b-col
              >
              <b-col></b-col>
            </b-row>
          </div> -->
        </b-tab>

        <b-tab>
          <template #title>
            <!-- <img  width="100px" src="@/assets/school_move2.svg" alt="" /> -->
            <p class="mt-1">{{ $t("InfoGraphicTheme_2") }}</p>
          </template>
          <div class="text-center mt-4">
            <div>
              <b-row>
                <b-col class="text-center">
                  <img
                    @click="OpemImageClass"
                    style="cursor:pointer"
                    src="@/assets/last.svg"
                    alt=""
                  />
                </b-col>
              </b-row>
              <b-modal
                hide-footer
                class="text-center"
                size="xl"
                v-model="ModalImgClass"
              >
                <b-row>
                  <b-col class="text-center">
                    <img width="100%" src="@/assets/last.svg" alt="" />
                  </b-col>
                </b-row>
              </b-modal>
            </div>
          </div>
          <!-- <div class="m-3 p-3">
            <b-row>
              <b-col>
                <div class="textColorViewStudentMove">
                  <p>
                    {{ $t("InfoGraphic_2") }}
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row class="m-3">
              <b-col></b-col>
              <b-col>
                <b-card class="infoCardView">
                 
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/class_move.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange10 = !ischange10"
                          variant="white"
                          class="text"
                        >
                          
                          <b-icon
                            class="IconStyle"
                            :icon="ischange10 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#1D70B8"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange10"
                        id="accordion-10"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              1. Ота-оналар фарзандини синфдан синфга ўтказиш
                              сабабларини кўрсатган ҳолда мактаб директори
                              номига ариза билан мурожаат қилади. <br />
                              2. Мактаб директори аризани қабул қилиб олган
                              кундан бошлаб бир иш куни ичида кўриб чиқади ва
                              ўқувчини синфдан синфга ўтказиш тўғрисида буйруқ
                              қабул қилади.
                              <br />
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
                 
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/class_move2.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange11 = !ischange11"
                          variant="white"
                          class="text"
                        >
                         
                          <b-icon
                            class="IconStyle"
                            :icon="ischange11 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#1D70B8"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange11"
                        id="accordion-11"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              - Синфдаги ўқувчилар сони белгиланган меъёрдан
                              ортиқ бўлган тақдирда, мактаб директори ўқувчини
                              синфдан синфга ўтказишни рад қилиши мумкин. <br />
                              - Синфдан синфга ўтказиш рад этилганлиги ҳақида
                              мактаб директори томонидан уч кун ичида
                              ота-оналарга ёзма равишда маълум қилинади. <br />
                              3. Мактаб директорининг ўқувчини синфдан синфга
                              ўтказиш тўғрисидаги буйруғи қабул қилингач, уч кун
                              ичида синф журнали ва ўқувчилар ҳаракати дафтарига
                              тегишли ёзув киритилади.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
               
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/school_move.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange12 = !ischange12"
                          variant="white"
                          class="text"
                        >
                          
                          <b-icon
                            class="IconStyle"
                            :icon="ischange12 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#1D70B8"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange12"
                        id="accordion-12"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              1. Фарзанд таълим олаётган мактаб директори номига
                              ариза берилади. Аризада фарзандини бошқа мактабга
                              ўтказиш сабаблари кўрсатилади. <br />
                              • Мазкур ариза 1 иш куни ичида кўриб чиқилиб, ўқиш
                              кўчирилаётган мактабга тақдим этиш учун тегишли
                              шаклда мактабдан кетиш талони берилади. <br />
                              2. Ўқиш кўчирилаётган мактаб директорига ўқишга
                              қабул қилиш тўғрисида ариза билан мурожаат қилади.
                              <br />
                              • Аризага мактабдан кетиш талони ва яшаш жойидаги
                              фуқароларнинг ўзини-ўзи бошқариш органи томонидан
                              берилган маълумотнома илова қилинади. <br />
                              • Ўқиш кўчирилаётган мактаб директори ариза ва
                              мактабдан кетиш талонини қабул қилиб олинган
                              куннинг ўзида ота-оналарга ўқувчи таълим олаётган
                              мактабга тақдим этиш учун мактабга келиш талонини
                              бериши лозим.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="m-3">
              <b-col></b-col>
              <b-col class="mt-4 pt-2">
                <b-card class="infoCardView">
                 
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/school_move2.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange13 = !ischange13"
                          variant="white"
                          class="text"
                        >
                          
                          <b-icon
                            class="IconStyle"
                            :icon="ischange13 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#1D70B8"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange13"
                        id="accordion-13"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              3. Фарзанд ўқиётган мактаб директорига мактабга
                              келиш талонини бериш лозим. <br />
                              • Бунда шу куннинг ўзида ўқувчини мактаб
                              ўқувчилари сафидан чиқариш тўғрисида буйруқ қабул
                              қилинади. <br />
                              4. Ҳужжатларни ўқиш кўчирилаётган мактаб
                              директорига топшириш лозим. Бунда шу куннинг ўзида
                              ўқувчини мактаб ўқувчилари сафига қабул қилиш
                              тўғрисида буйруқ чиқарилади.
                              <br />
                              • Ота-оналар фарзандини синфдан синфга ўтказиш
                              учун унинг сабабларини кўрсатган ҳолда мактаб
                              директори номига ариза билан мурожаат қилади.
                              Ариза бир иш куни ичида кўриб чиқилади ва ўқувчини
                              синфдан синфга ўтказиш тўғрисида буйруқ қабул
                              қилади. <br />
                              • Синфдаги ўқувчилар сони белгиланган меъёрдан
                              ортиқ бўлган тақдирда, мактаб директори ўқувчини
                              синфдан синфга ўтказишни рад қилиниши мумкин.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="InfografiyaViewMoveSchool">
                  <b-row>
                    <b-col>
                      <div>
                        <p class="textInfographViewSchool">
                          {{ $t("InfoGraphicTheme_2") }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                   
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/school_move4.svg" alt="" />
                    </b-col>
                  
                  </b-row>
                  <b-row>
                    <b-col>
                      <div></div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col class="mt-4 ">
                <b-card class="infoCardView">
                 
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center ">
                      <img width="80%" src="@/assets/doumentInter.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange15 = !ischange15"
                          variant="white"
                          class="text"
                        >
                         
                          <b-icon
                            class="IconStyle"
                            :icon="ischange15 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#1D70B8"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange15"
                        id="accordion-15"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Ота-оналар хорижий давлатда таълим олган
                              Ўзбекистон Республикаси фуқароси бўлган фарзандини
                              Ўзбекистон Республикасидаги мактабга ўтказиш
                              ҳуқуқига эга. Бунинг учун қуйидаги ҳаракатларни
                              амалга ошириш лозим:
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="m-3">
              <b-col></b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/documentIter2.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange17 = !ischange17"
                          variant="white"
                          class="text"
                        >
                         
                          <b-icon
                            class="IconStyle"
                            :icon="ischange17 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#1D70B8"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange17"
                        id="accordion-17"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              1. Хорижий давлатда таълим олган болани Ўзбекистон
                              Республикасидаги мактабга ўтказиш учун ота-оналар
                              томонидан мактаб директорига қуйидаги ҳужжатлар
                              тақдим этилади: <br />
                              - ота-она томонидан мактаб директори номига ариза;
                              <br />
                              - боланинг хорижий давлатда таълим олганлиги
                              ҳақидаги ҳужжатнинг (ҳужжатларнинг) асли ва
                              нотариал тасдиқланган таржимаси; <br />
                              - боланинг туғилганлик ҳақидаги гувоҳномаси
                              нусхаси;
                              <br />
                              - боланинг соғлиғи тўғрисида тиббий маълумотнома;
                              <br />
                              - боланинг 3,5 х 4,5 см ҳажмдаги 4 дона рангли
                              фотосурати; <br />
                              Мактаб директори тақдим этилган ҳужжатларни бир иш
                              куни ичида кўриб чиқади ва болани мактаб
                              ўқувчилари сафига қабул қилиш тўғрисида буйруқ
                              чиқаради.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/interdocument.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange18 = !ischange18"
                          variant="white"
                          class="text"
                        >
                          
                          <b-icon
                            class="IconStyle"
                            :icon="ischange18 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#1D70B8"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange18"
                        id="accordion-18"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              2. Мактаб директори боланинг хорижий давлатда
                              ўқиган таълим дастури мактабнинг ўқув дастурларига
                              мос келиш-келмаслигини аниқлайди ҳамда фанлар
                              бўйича аниқланган фарқларни жорий ёки навбатдаги
                              ўқув йилида бартараф этиш чораларини кўради.
                              <br />
                              3. Боланинг хорижий давлатда таълим олганлиги
                              ҳақидаги ҳужжат мавжуд бўлмаганда, мактаб
                              директори болани унинг ёши ва билим даражасига мос
                              синфга қабул қилиши лозим.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/international.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange19 = !ischange19"
                          variant="white"
                          class="text"
                        >
                          
                          <b-icon
                            class="IconStyle"
                            :icon="ischange19 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#1D70B8"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange19"
                        id="accordion-19"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              4. Болани мактаб ўқувчилари сафига қабул қилиш
                              тўғрисида буйруқ чиқарилгач, уч кун муддатда синф
                              журнали ва ўқувчилар ҳаракати дафтарига тегишли
                              ёзув киритилади.
                              <br />
                              <a
                                target="_blank"
                                style="color:red"
                                href=" https://lex.uz/docs/2678463"
                              >
                                https://lex.uz/docs/2678463</a
                              >
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col></b-col>
            </b-row>
          </div> -->
        </b-tab>
        <b-tab>
          <template #title>
            <!-- <img width="100px" src="@/assets/school_move.svg" alt="" /> -->
            <p class="mt-1">{{ $t("InfoGraphicTheme_3") }}</p>
          </template>
          <div>
            <b-row>
              <b-col class="text-center">
                <img
                  @click="OpemImage"
                  style="cursor:pointer"
                  src="@/assets/school_move_to_move.svg"
                  alt=""
                />
              </b-col>
            </b-row>
            <b-modal
              hide-footer
              class="text-center"
              size="xl"
              v-model="ModalImg"
            >
              <b-row>
                <b-col class="text-center">
                  <img
                    width="100%"
                    src="@/assets/school_move_to_move.svg"
                    alt=""
                  />
                </b-col>
              </b-row>
            </b-modal>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <!-- <img  width="100px" src="@/assets/class_move2.svg" alt="" /> -->
            <p class="mt-1">{{ $t("InfoGraphicTheme_4") }}</p>
          </template>
          <div class="text-center mt-4">
            <!-- <img  src="@/assets/chet.svg" alt=""> -->
            <div>
              <b-row>
                <b-col>
                  <img
                    @click="OpemImageChet"
                    style="cursor:pointer"
                    src="@/assets/chet_school.svg"
                    alt=""
                  />
                </b-col>
              </b-row>
              <b-modal
                hide-footer
                class="text-center"
                size="xl"
                v-model="ModalImgChet"
              >
                <b-row>
                  <b-col class="text-center">
                    <img width="100%" src="@/assets/chet_school.svg" alt="" />
                  </b-col>
                </b-row>
              </b-modal>
            </div>
          </div>
        </b-tab>
        <!-- <b-tab>
          <template #title>
            <img width="100px" src="@/assets/Budget_1.svg" alt="" />
            <p class="mt-3">{{ $t("InfoGraphicTheme_3") }}</p>
          </template>
          <div class="m-3 p-3">
            <b-row>
              <b-col>
                <div class="textInfographViewBudjet">
                  <p>
                    {{ $t("InfoGraphic_3") }}
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row class="m-3">
              <b-col></b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/Budget_1.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange20 = !ischange20"
                          variant="white"
                          class="text"
                        >
                         
                          <b-icon
                            class="IconStyle"
                            :icon="ischange20 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#52A0FF"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange20"
                        id="accordion-20"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              - халқ таълими муассасаларининг кўчмас мол-мулкини
                              ижарага беришдан тушган маблағлар (Ўзбекистон
                              Республикаси Давлат активларини бошқариш
                              агентлигининг ҳудудий органлари ҳузуридаги Давлат
                              мулкини ижара бериш маркази давлат унитар
                              корхоналарига 1 фоизи ва электрон савдо майдончаси
                              операторига тўланган тўловдан ташқари)
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/Budget_2.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange21 = !ischange21"
                          variant="white"
                          class="text"
                        >
                          <b-icon
                            class="IconStyle"
                            :icon="ischange21 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#52A0FF"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange21"
                        id="accordion-21"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              - ўқув дастурларида назарда тутилмаган (давлат
                              таълим стандартларидан ташқари) машғулотлар бўйича
                              қўшимча таълим беришдан олинган даромадлар; <br />
                              - юридик ва жисмоний шахсларнинг, шу жумладан
                              Ўзбекистон Республикаси норезидентларининг
                              ҳомийлик хайриялари;
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/Budget_3.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange22 = !ischange22"
                          variant="white"
                          class="text"
                        >
                          <b-icon
                            class="IconStyle"
                            :icon="ischange22 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#52A0FF"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange22"
                        id="accordion-22"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              - халқ таълими муассасалари фаолият ихтисослиги
                              бўйича товарларни (ишларни, хизматларни)
                              реализация қилишдан олинган даромадлар;

                              <br />- вақтинча бўш турган миллий валютадаги
                              маблағларни тижорат банклари депозитларига
                              белгиланган тартибда жойлаштиришдан олинадиган
                              даромадлар;
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="m-3">
              <b-col></b-col>
              <b-col class="mt-4 pt-4">
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/Budget_4.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange23 = !ischange23"
                          variant="white"
                          class="text"
                        >
                          <b-icon
                            class="IconStyle"
                            :icon="ischange23 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#52A0FF"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange23"
                        id="accordion-23"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              - муассаса ва ташкилотларнинг илмий-педагогик ва
                              меҳнат фаолияти билан шуғулланувчи ходимларининг
                              лавозим маошига белгиланган қўшимча ҳақни
                              молиялаштириш учун ажратилган маблағларнинг молия
                              йили якунига қадар ишлатилмай қолган бюджет
                              маблағлари;
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="InfografiyaViewMoveSchool">
                  <b-row>
                    <b-col>
                      <div>
                        <p class="textInfographViewSchool">
                          {{ $t("InfoGraphicTheme_3") }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    
                    <b-col class="text-center">
                      <img width="100%" src="@/assets/Budget_9.svg" alt="" />
                    </b-col>
                   
                  </b-row>
                  <b-row>
                    <b-col>
                      <div></div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col class="mt-4 pt-4">
                <b-card class="infoCardView">
                 
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="100%" src="@/assets/Budget_5.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange24 = !ischange24"
                          variant="white"
                        >
                         
                          <b-icon
                            class="IconStyle"
                            :icon="ischange24 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#52A0FF"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange24"
                        id="accordion-24"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              - халқаро молия ташкилотлари ва хорижий
                              давлатларнинг грантлари ва техник кўмаклашиш
                              маблағлари; <br />
                              - қонунчилик ҳужжатлари билан тақиқланмаган бошқа
                              маблағлар.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="m-3">
              <b-col></b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/Budget_6.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange25 = !ischange25"
                          variant="white"
                          class="text"
                        >
                         
                          <b-icon
                            class="IconStyle"
                            :icon="ischange25 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#52A0FF"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange25"
                        id="accordion-25"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              - Жамғармага тушум қилиш масъуллар диққат
                              эътиборида эмас; <br />
                              - Тегишли қонунчилик нормалари асосида шартномалар
                              тузилмасдан, белгиланган тартибга амал қилинмасдан
                              жамғармага туширилиши керак бўлган маблағлар
                              масъулларнинг шахсий мақсадлари учун ишлатиб
                              юборилади;
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/Budget_7.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange26 = !ischange26"
                          variant="white"
                          class="text"
                        >
                        
                          <b-icon
                            class="IconStyle"
                            :icon="ischange26 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#52A0FF"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange26"
                        id="accordion-26"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              - Жамғармага тушум қилувчи қўшимча манбалар излаб
                              топилмайди; <br />
                              - Марказлаштирилган молия-бухгалтерия хизматида
                              бир ташкилотдаги қолдиқ сумма ҳисобига бошқа
                              ташкилот манфаати учун маблағлар ишлатилади;
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img width="80%" src="@/assets/Budget_8.svg" alt="" />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange27 = !ischange27"
                          variant="white"
                          class="text"
                        >
                          
                          <b-icon
                            class="IconStyle"
                            :icon="ischange27 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#52A0FF"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange27"
                        id="accordion-27"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              - Марказлаштирилган молия-бухгалтерия хизматига
                              ўтгандан кейинги давр таҳлил қилинса тушумлар
                              камайиб кетган.
                              <br />
                              - Бюджетдан ташқари маблағлардан ўқитувчи
                              ходимларга маош тайинланишида ушбу ҳисобланган
                              суммага нисбатан Ягона ижтимоий тўловнинг
                              қўлланилиши (гарчи ушбу маблағлар таълим
                              муассасаси томонидан ишлаб топилган бўлишига
                              қарамасдан)
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col></b-col>
            </b-row>
          </div>
        </b-tab> -->
        <!-- <b-tab>
          <template #title>
            <img width="100px" src="@/assets/teacher/teacher_1.svg" alt="" />
            <p class="mt-4">{{ $t("InfoGraphicTheme_4") }}</p>
          </template>
          <div class="m-3 p-3">
            <b-row>
              <b-col>
                <div class="textInfographViewBudjet">
                  <p>
                    {{ $t("InfoGraphic_4") }}
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row class="m-3">
              <b-col></b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/teacher/teacher_1.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange28 = !ischange28"
                          variant="white"
                          class="text"
                        >
                          
                          <b-icon
                            class="IconStyle"
                            :icon="ischange28 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#3F3D56"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange28"
                        id="accordion-28"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Ўқитувчи ва раҳбарият, ўқитувчи ва ўқувчилар
                              ўртасидаги муносабатларни тартибга солиш, ишлаб
                              чиқилган меъёрий ҳужжатларнинг таълим жараёнида
                              таъсирини баҳолаш механизмларини жорий қилиш қилиш
                              юзасидан қуйидаги бир қанча фикр ва таклифларни
                              илгари суриш мумкин
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/teacher/teacher_2.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange29 = !ischange29"
                          variant="white"
                          class="text"
                        >
                          <b-icon
                            class="IconStyle"
                            :icon="ischange29 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#3F3D56"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange29"
                        id="accordion-29"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Бугунги кунда ёшлар ўртасида жуда кўплаб ёт
                              ғояларнинг кириб келиши, оммавий маданият, турли
                              хил ташқи таъсирлар оқибатида оилавий аҳволи,
                              ижтимоий келиб чиқиши каби ҳолатлар мактабларда
                              ММИБДЎ-лари учун жуда ҳам ортиқча зўриқишни юзага
                              келтириб чиқармоқда.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/teacher/teacher_3.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange30 = !ischange30"
                          variant="white"
                          class="text"
                        >
                          <b-icon
                            class="IconStyle"
                            :icon="ischange30 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#3F3D56"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange30"
                        id="accordion-30"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Ота-она фарзандларини қаровсиз ҳолда васий
                              тайинламасдан чет элларга узоқ муддатга (ишлашга,
                              ўқишга, саёҳатга) кетиб қолиши (даволанишга ва иш
                              жойидан қисқа муддатларга хизмат сафарига
                              чиқаётганлар бундан мустасно).
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="m-3">
              <b-col></b-col>
              <b-col class="mt-4">
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/teacher/teacher_4.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange31 = !ischange31"
                          variant="white"
                          class="text"
                        >
                          <b-icon
                            class="IconStyle"
                            :icon="ischange31 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#3F3D56"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange31"
                        id="accordion-31"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Икки сменали ўқитиш ташкил қилинган мактабларда
                              раҳбар ходимларга (директор, ЎИБ директор
                              ўринбосари, ММИБ директор ўринбосари) табелда иш
                              соати ҳафталик 40 соат қилиб белгиланган бўлсада,
                              тўлиқ икки сменада дарслар якунигача бўлган вақт
                              учун жавобгарлик юклатилган.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="InfografiyaViewMoveSchool">
                  <b-row>
                    <b-col>
                      <div>
                        <p class="textInfographViewSchool">
                          {{ $t("InfoGraphicTheme_4") }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                  
                    <b-col class="text-center">
                      <img
                        width="100%"
                        src="@/assets/teacher/teacher_main3.svg"
                        alt=""
                      />
                    </b-col>
                   
                  </b-row>
                  <b-row>
                    <b-col>
                      <div></div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col class="mt-4">
                <b-card class="infoCardView">
                  
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/teacher/teacher_6.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange32 = !ischange32"
                          variant="white"
                        >
                         
                          <b-icon
                            class="IconStyle"
                            :icon="ischange32 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#3F3D56"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange32"
                        id="accordion-32"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Болаларни мактабга қабул қилинишида тиббий кўрик
                              жараёнига ҳар бир боланинг ташхиси бўйича шахсий
                              жавобгарлиги зарур. Амалиётда кўпинча ақлий
                              томонлама ривожланиши суст бўлган болаларга ҳам
                              соғлом деб маълумотномалар берилиши
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="m-3">
              <b-col></b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/teacher/teacher_7.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange33 = !ischange33"
                          variant="white"
                          class="text"
                        >
                          
                          <b-icon
                            class="IconStyle"
                            :icon="ischange33 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#3F3D56"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange33"
                        id="accordion-33"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Мобил алоқа хизмати кўрсатувчи компаниялар
                              томонидан мактаб ёшидаги болалар учун факат
                              uz-доменидан фойдаланадиган маҳсус сим карталар
                              яратиш. Агарда мактаб ўқувчиси одатий сим картадан
                              фойдаланадиган бўлса ота-онани жаримага тортиш
                              масаласини қўйиш керак.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/teacher/teacher_8.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange34 = !ischange34"
                          variant="white"
                          class="text"
                        >
                          
                          <b-icon
                            class="IconStyle"
                            :icon="ischange34 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#3F3D56"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange34"
                        id="accordion-34"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Ўқитувчилар томонидан раҳбариятга ёки юқори
                              ташкилотларга турли хилдаги мурожаатларни фақат
                              жойига борган ҳолда қоғоз шаклида амалга
                              оширилмасдан балки ҳар бир фойдаланувчини ўзига
                              тегишли бўлган шахсий кабинетини яратиш орқали
                              шахсий телефонидан фойдаланган ҳолда электрон
                              ҳужжат алмашинув тизими йўлга қўйилса айни муддао
                              бўларди.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/teacher/teacher_5.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange35 = !ischange35"
                          variant="white"
                          class="text"
                        >
                         
                          <b-icon
                            class="IconStyle"
                            :icon="ischange35 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#3F3D56"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange35"
                        id="accordion-35"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Меҳнат кодекси бўйича белгиланган ишлаш соати
                              ортиб кетади. Ўқитувчиларни методик куни бир неча
                              кунни эмас, балки аниқ қилиб ҳар бир фанни ўзини
                              методик кунига белгиланади. Буни албатта 2 сменали
                              мактабларда ҳам алоҳида эътиборга олиш лозим.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col></b-col>
            </b-row>
          </div>
        </b-tab> -->
        <!-- <b-tab>
          <template #title>
            <img width="100px" src="@/assets/Econom/econom_1.svg" alt="" />
            <p class="mt-3">{{ $t("InfoGraphicTheme_5") }}</p>
          </template>
          <div class="m-3 p-3">
            <b-row>
              <b-col>
                <div class="textInfographViewBudjet">
                  <p>
                    {{ $t("InfoGraphic_5") }}
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row class="m-3">
              <b-col></b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/Econom/econom_1.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange36 = !ischange36"
                          variant="white"
                          class="text"
                        >
                         
                          <b-icon
                            class="IconStyle"
                            :icon="ischange36 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#D0CDE1"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange36"
                        id="accordion-36"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Мактаблардаги дарс жадвалларини аниқ қилиб ягона
                              марказлашган ҳолда ўз нормативи бўйича маҳсус
                              электрон дастур орқали тузишни йўлга қўйиш керак.
                              Бунинг учун мактаблардаги синфлар сони, ўқувчилар
                              сони, синф хоналари сони ва мактаб неча сменалик
                              эканлиги аниқланиб олиниши керак.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/Econom/econom_2.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange37 = !ischange37"
                          variant="white"
                          class="text"
                        >
                          <b-icon
                            class="IconStyle"
                            :icon="ischange37 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#D0CDE1"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange37"
                        id="accordion-37"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Ходимларнинг иш ҳақи табеларини тегишли ходимлар
                              томонидан электрон дастур ёрдамида юритилиши йўлга
                              қўйилиб, “Узасбо” дастуридаги табел тарификация
                              билан бир ҳолатга келтирилиб иккала дастур
                              ўртасида интеграция йўлга қўйилса, бунда
                              жўнатилган табел биринчи навбатда кадрлар бўлимига
                              келса кадрлар бўлими мутахассислиги бўйича
                              берилган
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/Econom/econom_3.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange38 = !ischange38"
                          variant="white"
                          class="text"
                        >
                          <b-icon
                            class="IconStyle"
                            :icon="ischange38 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#D0CDE1"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange38"
                        id="accordion-38"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Ўқитувчилар томонидан раҳбариятга ёки юқори
                              ташкилотларга турли хилдаги мурожаатларни фақат
                              жойига борган ҳолда қоғоз шаклида амалга
                              оширилмасдан балки ҳар бир фойдаланувчини ўзига
                              тегишли бўлган шахсий кабинетини яратиш орқали
                              шахсий телефонидан фойдаланган ҳолда электрон
                              ҳужжат алмашинув тизими йўлга қўйилса айни муддао
                              бўларди.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="m-3">
              <b-col></b-col>
              <b-col class="mt-4 ">
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/Econom/econom_4.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange39 = !ischange39"
                          variant="white"
                          class="text"
                        >
                          <b-icon
                            class="IconStyle"
                            :icon="ischange39 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#D0CDE1"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange39"
                        id="accordion-39"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Мактабларда бюджет маблағларини ўзлаштиришга олиб
                              келувчи коррупцион ҳолатлардан яна бири бу
                              ўтилмаган дарсларни ўтилди деб табелга ёзиб юбориш
                              ҳоллари.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="InfografiyaViewMoveSchool">
                  <b-row>
                    <b-col>
                      <div>
                        <p class="textInfographViewSchool">
                          {{ $t("InfoGraphicTheme_5") }}
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                   
                    <b-col class="text-center">
                      <img
                        width="100%"
                        src="@/assets/Econom/econom_main.svg"
                        alt=""
                      />
                    </b-col>
                 
                  </b-row>
                  <b-row>
                    <b-col>
                      <div></div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col class="mt-4">
                <b-card class="infoCardView">
                 
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/Econom/econom_6.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange40 = !ischange40"
                          variant="white"
                        >
                          
                          <b-icon
                            class="IconStyle"
                            :icon="ischange40 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#D0CDE1"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange40"
                        id="accordion-40"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Диплом сохтакорлигини олдини олиш учун Олий
                              таълимни тегишли сайтлари билан интеграцияни йўлга
                              қўйиш.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="m-3">
              <b-col></b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/Econom/econom_7.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange41 = !ischange41"
                          variant="white"
                          class="text"
                        >
                        
                          <b-icon
                            class="IconStyle"
                            :icon="ischange41 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#D0CDE1"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange41"
                        id="accordion-41"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Малака тоифа сертификатларини ҳаққонийлигини
                              текшириш учун тегишли сайтлар билан интеграцияни
                              йўлга қўйиш.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="30%"
                        src="@/assets/Econom/econom_8.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange42 = !ischange42"
                          variant="white"
                          class="text"
                        >
                        
                          <b-icon
                            class="IconStyle"
                            :icon="ischange42 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#D0CDE1"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange42"
                        id="accordion-42"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Ўлик жонлар муаммосини оз бўлсада бартараф қилиш
                              юзасидан персоналлаштириш маркази билан
                              интеграцияни йўлга қўйиб паспорт ва id карта
                              маълумотларини тортиб олган ҳолда уни
                              чегара-божхона базасидан республика ташқарисига
                              чиқиб кетганлар маълумотларини таққослаш.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="infoCardView">
                  <b-row>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col class="text-center">
                      <img
                        width="80%"
                        src="@/assets/teacher/teacher_5.svg"
                        alt=""
                      />
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                  </b-row>
                  <b-row class="mt-2">
                    <b-col>
                      <div class="text-right">
                        <b-button
                          block
                          @click="ischange43 = !ischange43"
                          variant="white"
                          class="text"
                        >
                          
                          <b-icon
                            class="IconStyle"
                            :icon="ischange43 ? 'chevron-up' : 'chevron-down'"
                          ></b-icon></b-button
                        ><b-icon
                          style="color:#D0CDE1"
                          icon="arrow-right-circle-fill"
                        ></b-icon>
                      </div>
                      <b-collapse
                        :visible="ischange43"
                        id="accordion-43"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <p class="textInfographView">
                              Ходимларнинг алимент ва шунга тенглаштирилган
                              руйхатлари рестрлари хам тегишли ташкилотлар билан
                              уланган (МИБ, Почта) дастур ишлаб чиқилиб электрон
                              жўнатиш имкони яратилса.
                            </p>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col></b-col>
            </b-row>
          </div>
        </b-tab> -->
      </b-tabs>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ModalImgQabul: false,
      ModalImg: false,
      ModalImgChet: false,
      buttonShow: false,
      buttonShowSchool: false,
      showSchool: false,
      showUniversity: false,
      ModalImgClass: false,
      ischange1: false,
      ischange2: false,
      ischange3: false,
      ischange4: false,
      ischange5: false,
      ischange6: false,
      ischange7: false,
      ischange8: false,
      ischange9: false,
      ischange10: false,
      ischange11: false,
      ischange12: false,
      ischange13: false,
      ischange14: false,
      ischange15: false,
      ischange16: false,
      ischange17: false,
      ischange18: false,
      ischange19: false,
      ischange20: false,
      ischange21: false,
      ischange22: false,
      ischange23: false,
      ischange24: false,
      ischange25: false,
      ischange26: false,
      ischange27: false,
      ischange28: false,
      ischange29: false,
      ischange30: false,
      ischange31: false,
      ischange32: false,
      ischange33: false,
      ischange34: false,
      ischange35: false,
      ischange36: false,
      ischange37: false,
      ischange38: false,
      ischange39: false,
      ischange40: false,
      ischange41: false,
      ischange42: false,
      ischange43: false,
    };
  },
  methods: {
    Large(img) {
      img.style.transform = "scale(1.5)";
    },
    OpemImage() {
      this.ModalImg = true;
    },
    OpemImageChet() {
      this.ModalImgChet = true;
    },
    OpemImageClass() {
      this.ModalImgClass = true;
    },
    OpemImageQabul() {
      this.ModalImgQabul = true;
    },
  },
};
</script>
<style scoped>
.ImgLarger:hover {
  transform: scale(1.5);
}
.InfografiyaViewEnterSchool {
  background-color: #e2ecfa;
  min-width: 300px;
  border-radius: 20px;
  box-shadow: rgba(4, 20, 41, 0.56) 0px 22px 70px 4px;
  transition: all 5s;
}

/* rgba(4,20,41) */
.InfografiyaViewMoveSchool {
  background-color: #e2ecfa;
  border-radius: 20px;
  min-width: 300px;
  box-shadow: rgba(29, 112, 184, 0.56) 0px 22px 70px 4px;
}
.textColorViewStudent {
  color: rgb(4, 20, 41);
  font-size: 18px;
  font-weight: bold;
}
.infoCardView {
  border-radius: 20px;
  min-height: 200px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.show {
  z-index: 1;
}
.showMinus {
  z-index: -1;
}
.image {
  background-position: center;
  background-image: url("/images/desktop2.svg") !important;
  background-size: 800px 800px;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 600px;
}
.iconPlaceRight {
  margin-top: 120px;
  margin-left: 20px;
}
.iconPlaceLeft {
  margin-right: 20px;
  margin-top: 120px;
}
.iconPlaceUp {
  margin-left: 190px;
}
.iconPlaceDown {
  margin-top: 40px;
  margin-left: 200px;
}
</style>
